<template>
  <div class="dress">
    <v-container v-if="employee">
      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Información Personal</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4">
          NOMBRES: {{ employee.first_name + " " + employee.last_name }}
        </v-col>
        <v-col cols="4"> APELLIDOS: {{ employee.last_name }} </v-col>
        <v-col cols="4"> TIPO DE DOCUMENTO: {{ employee.id_type }} </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4"> # DOCUMENTO: {{ employee.number_id }} </v-col>
        <v-col cols="4"> TELEFONO: {{ employee.phone }} </v-col>
        <v-col cols="4"> CORREO: {{ employee.email }} </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4"> DIRECCION: {{ employee.addres }} </v-col>
        <v-col cols="4">
          CARGO: {{ getEmployeePositionLan(employee.position) }}
        </v-col>
        <v-col cols="4"> SEDE: {{ employee.office.name }} </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Documentos</v-toolbar-title
            >
            <v-chip class="ma-2" color="secondary" text-color="white" small>
              {{ employee.files.length }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" small fab dark color="indigo" @click="upload">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersFiles"
            :items="employee.files"
            class="elevation-1 mb-7"
            no-data-text="No hay registros agregados..."
            hide-default-footer
          >
            <template v-slot:[`item.created_at`]="item">
              {{ item.item.created_at | formatDate }}
            </template>
            <template v-slot:[`item.actions`]="item">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="blue"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadFile(item.item.url)"
                    class="px-1"
                  >
                    mdi-download-outline
                  </v-icon>
                </template>
                <span>DESCARGAR</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteFile(item.item)"
                    class="px-1"
                  >
                    mdi-delete-outline
                  </v-icon>
                </template>
                <span>ELIMINAR</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog loader -->
    <v-dialog
      v-model="this.$store.state.employee.isLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog upload file -->
    <v-dialog v-model="dialogUpload" width="550" persistent>
      <v-card>
        <v-card-title> Agregar Documento </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formUpload" v-model="formUpload">
            <v-file-input
              accept="application/pdf,application/vnd.ms-excel,image/*,.doc,.docx,.pdf"
              label="Seleccionar Documento"
              v-model="model.file"
              :rules="[rules.required]"
              show-size
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="cancelUpload">Cancelar</v-btn>
          <v-btn text color="primary" @click="saveUpload">Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete file dialog -->
    <v-dialog v-model="dialogDeleteFile" width="500" persistent>
      <v-card>
        <v-card-text class="py-4" v-if="currentFile">
          Seguro que desea eliminar el documento: {{ currentFile.name }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-4"
            @click="deleteFileConfirm"
            small
          >
            Confirmar
          </v-btn>

          <v-btn color="error" text class="mr-4" @click="dialogDeleteFile = false" small>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { SET_EMPLOYEE } from "@/store/employee";
import { SET_ALERT } from "@/store/alert";
import { mapGetters } from "vuex";
export default {
  name: "Show",
  props: ["id"],
  filters: {
    formatDate(value) {
      const date = value.split("T");
      return date[0];
    },
  },
  data: () => ({
    formUpload: true,
    dialogUpload: false,
    dialogDeleteFile: false,
    currentFile: null,
    employee: null,
    headersFiles: [
      { text: "Nombre", value: "name", sortable: false },
      { text: "Tipo", value: "mime_type", sortable: false },
      { text: "Fecha", value: "created_at", sortable: false },
      { text: "Actiones", value: "actions", sortable: false },
    ],
    model: {
      file: null,
    },
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
  }),
  created() {
    this.$store.dispatch("getEmployee", this.id);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_EMPLOYEE) {
        this.employee = state.employee.employee;
      }
    });
  },
  computed: {
    ...mapGetters(["getEmployeePositionLan", "getStatusColor", "getStatusLan"]),
  },
  methods: {
    upload() {
      this.dialogUpload = true;
    },
    cancelUpload() {
      this.dialogUpload = false;
      this.model = {
        file: null,
      };
    },
    saveUpload() {
      if (this.model.file == null) {
        this.$store.commit(SET_ALERT, {
          message: "Debe seleccionar los archivos",
          type: "error",
        });
        return;
      }

      this.model.employee = this.id;
      this.$store.dispatch("addFileEmployee", this.createFormData());
      this.cancelUpload();
    },
    createFormData() {
      let formData = new FormData();
      Object.entries(this.model).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((element, index) => {
            formData.append(key + "[" + index + "]", element);
          });
        } else {
          if (value !== null && value !== "") {
            formData.append(key, value);
          }
        }
      });
      return formData;
    },
    downloadFile(url){
      window.open(url, '_blank');
    },
    deleteFile(file){
      this.currentFile = file;
      this.dialogDeleteFile = true;
    },
    deleteFileConfirm(){      
      this.$store.dispatch("deleteFileEmployee", {
        employee: this.employee.id,
        file: this.currentFile.id
      });
      this.dialogDeleteFile = false;
      this.currentFile = null;
    }
  },
};
</script>
  